// Add to whitelist component

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_bgColorLight } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { CustomSelect } from "../../LayerLibrary/EditLayer/EditLayerStyle";
import { IAdminUser, IAdminUserData, IAdminWhitelistUser } from "./UserManagementInterfaces";
import { ChangeEvent, ReactNode, useState } from "react";
import { CustomTextField } from "../../LayerLibrary/EditLayer/EditLayer";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from "dayjs";
import { ToastNotification } from "../../ToastNotifications";
import { AddToAccountWhitelist } from "./UserManagementOps";

const WHITELIST_TEXT_MAX_LENGTH = 400;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AddToWhitelistProps
{
  showAddToWhitelist: boolean;
  setShowAddToWhitelist: any;
  userData?: IAdminUserData;
  OnNotifyAddToWhitelist: any;
}

//-------------------------------------------------------------------------------
// Add to whitelist component
//-------------------------------------------------------------------------------
const AddToWhitelist = (props: AddToWhitelistProps) => 
{


  const [localOrgID, setLocalOrgID] = useState<number|undefined>(undefined);
  const [localWhitelistText, setLocalWhitelistText] = useState<string>('');
  const [localExpirationDate, setLocalExpirationDate] = useState<Dayjs|null>(null);
  




  //-------------------------------------------------------------------------------
  // Close/cancel window.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setShowAddToWhitelist(false);

    setLocalOrgID(undefined);
    setLocalWhitelistText('');
    setLocalExpirationDate(null);
  }

  //-------------------------------------------------------------------------------
  // Accept/OK.
  //-------------------------------------------------------------------------------
  async function OnAccept()
  {
    if(!props.userData) return;

    // Validate the organization

    if(!localOrgID)
    {
      ToastNotification('error', 'Please select an organization');
      return;
    }

    // Validate the list of emails

    // Split the email text based on newlines (trim spaces, remove empty lines)
    const whiteListEmails: string[] = localWhitelistText.split(/\r?\n/).map(s => s.trim()).filter(line => line.length > 0);
    if(whiteListEmails.length === 0)
    {
      ToastNotification('error', 'Please specify one or more email addresses');
      return;
    }

    // Make sure none of these emails already exist in the whitelist

    for(let i=0; i < whiteListEmails.length; i++)
    {
      const lowerCaseEmail = whiteListEmails[i].toLowerCase();
      const foundWhitelistUser: IAdminWhitelistUser | undefined = props.userData.whitelist.find(w => w.email.toLowerCase() === lowerCaseEmail);
      if(foundWhitelistUser)
      {
        ToastNotification('error', `${foundWhitelistUser.email} is already whitelisted.`);
        return;
      }
    }

    // Make sure none of these emails already exist in the user list

    for(let i=0; i < whiteListEmails.length; i++)
    {
      const lowerCaseEmail = whiteListEmails[i].toLowerCase();
      const foundUser: IAdminUser | undefined = props.userData.users.find(u => u.email.toLowerCase() === lowerCaseEmail);
      if(foundUser)
      {
        ToastNotification('error', `${foundUser.email} already has an account`);
        return;
      }
    }

    // Validate the expiration date

    if(localExpirationDate)
    {
      if(localExpirationDate.isValid() === false)
      {
        ToastNotification('error', 'The expiration date is not valid');
        return;
      }
      if(localExpirationDate.isBefore(dayjs()))
      {
        ToastNotification('error', 'The expiration date cannot be in the past');
        return;
      }
    }

    // Call server

    const ret: boolean = await AddToAccountWhitelist(localOrgID, whiteListEmails, localExpirationDate?.toJSON());
    if(ret === false)
      return;

    // Notify caller
    props.OnNotifyAddToWhitelist();

    // Close this window
    OnClose();
  }

  //-------------------------------------------------------------------------------
  // The color scheme type combo box was changed.
  //-------------------------------------------------------------------------------
  const OnOrgChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    const newOrgID: number = event.target.value as number;
    if(!newOrgID) return;

    setLocalOrgID(newOrgID);
  }

  //-------------------------------------------------------------------------------
  // The whitelist text field has changed.
  //-------------------------------------------------------------------------------
  function OnWhitelistTextFieldChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    setLocalWhitelistText(event.target.value);
  }











  if(!props.userData) return null;

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog disablePortal 
            open={props.showAddToWhitelist === true}
            onClose={OnClose} maxWidth='md'
            PaperProps={{ sx: { minHeight: '30vh', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
            Add to Account Whitelist
          </Typography>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>
        <Stack sx={{ mt: 2, alignItems: 'left' }}>

          {/* Org selection */}

          <Stack direction='column' sx={{ ml: 0, width: '100%' }}>

            <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
              Organization
            </Typography>

            <CustomSelect variant='standard' size='small'
                          value={localOrgID}
                          onChange={OnOrgChanged}
                          sx={{ p: 0.5 }}>

              {props.userData.orgs.map(org => 

                <MenuItem key={org.id} value={org.id}>
                  <Typography sx={{ color: theme_textColorMain, opacity: 0.8, fontSize: '1.0rem' }}>
                    {org.name}
                  </Typography>
                </MenuItem>

              )}

            </CustomSelect>
          </Stack>

          {/* Text box for emails (one per line)  */}

          <CustomTextField variant='filled' size='small' autoComplete='off' multiline rows={10} maxRows={10}
                           value={localWhitelistText}
                           onChange={OnWhitelistTextFieldChanged}
                           inputProps={{ maxLength: WHITELIST_TEXT_MAX_LENGTH }}
                           label=
                             {
                               <Typography sx={{fontSize:'0.8rem',color: theme_textColorBlended+'B0'}}>
                                 New emails to whitelist (one per line)
                               </Typography>
                             } 
                           sx={{ mt: 3, p: 0, width: '100%' }}/>
          
          {/* Expiration date picker */}

          <Typography sx={{ mt: 3, fontSize: '0.8rem', color: theme_textColorBlended }}>
            Account Expiration Date (optional)
          </Typography>

          <Typography sx={{ mt: 0, fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
            Users must sign up before this date.  Once created, their account will expire on this date.
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                        value={localExpirationDate}
                        onChange={(newValue) => setLocalExpirationDate(newValue)}

                        sx={{ mt: 0.5, 
                              input: { backgroundColor: theme_bgColorLight1+'70', color: theme_textColorMain+'A0', fontSize: '1.0rem' } 
                           }}

                           // Modify the calendar popup bg color
                           slotProps={{
                            layout: {
                              sx: {
                                borderRadius: '2px',
                                backgroundColor: theme_bgColorLight+'C0',
                              }
                            }
                          }}
            />
          </LocalizationProvider>

        </Stack>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

      <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold', textTransform: 'none' }}
                      onClick={_=>OnAccept()}>
                Add New Users
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default AddToWhitelist;
