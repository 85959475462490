// User management operations

import { CallServer, CallServerResults } from "../../CallServer";
import { ToastNotification } from "../../ToastNotifications";
import { IAdminOrg, IAdminUser, IAdminUserData, IAdminUserRole, IAdminWhitelistUser } from "./UserManagementInterfaces";


//-------------------------------------------------------------------------------
// Load user and whitelist info.
//-------------------------------------------------------------------------------
export async function LoadAdminUserInfo(setIsRunning: any = undefined): Promise<IAdminUserData | undefined>
{
  // Call the server to get the data

  const server = new CallServer();

  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('get', '/users');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    const userData: IAdminUserData | undefined = result.data;
    if(!userData)
    {
      ToastNotification('error', "Unable to load admin user data")
      return undefined;
    }

    // Sort the lists

    const sortedUserData: IAdminUserData =
    {
      ...userData,
      users: userData.users.sort((a: IAdminUser, b: IAdminUser) => (a.first_name + ' ' + a.last_name).localeCompare((b.first_name + ' ' + b.last_name))),
      whitelist: userData.whitelist.sort((a: IAdminWhitelistUser, b: IAdminWhitelistUser) => a.email.localeCompare(b.email)),
      orgs: userData.orgs.sort((a: IAdminOrg, b: IAdminOrg) => a.name.localeCompare(b.name))
    }

    // Success
    return sortedUserData;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load admin user data")
    return undefined;
  }
}

//-------------------------------------------------------------------------------
// Add new entries to the account whitelist.
//-------------------------------------------------------------------------------
export async function AddToAccountWhitelist(org_id: number, emails: string[], expiration_date: string|undefined, setIsRunning: any = undefined): Promise<boolean>
{
  const server = new CallServer();
  server.Add('users',
    {
      org_id: org_id,
      emails: emails,
      expiration_date: expiration_date
    });
  
  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('post', '/account_whitelist');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to add new entries to the account whitelist")
    return false;
  }
}

//-------------------------------------------------------------------------------
// Delete an email from the account whitelist.
//-------------------------------------------------------------------------------
export async function DeleteFromAccountWhitelist(email: string, setIsRunning: any = undefined): Promise<boolean>
{
  const server = new CallServer();
  server.Add('email', email);
  
  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('delete', '/account_whitelist');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to delete email from the account whitelist")
    return false;
  }
}

//-------------------------------------------------------------------------------
// Returns all unique orgs for which the specified user has roles.
//-------------------------------------------------------------------------------
export function GetListOfOrgsWithRolesForUser(user: IAdminUser): number[]
{
  const uniqueOrgIDs: number[] = [...new Set(user.roles.map(item => item.org_id))];
  return uniqueOrgIDs;
}

//-------------------------------------------------------------------------------
// Returns all roles for the specified user+org combo.
//-------------------------------------------------------------------------------
export function GetRolesForOrgForUser(user: IAdminUser, orgID: number): IAdminUserRole[]
{
  return user.roles.filter(r => r.org_id === orgID);
}