// User profile ops

import { MapScaleUnits } from "./AppSettingsDialog";
import { CallServer, CallServerResults } from "./CallServer";
import Debug from "./Debug";
import { DEFAULT_TERRAIN_3D_EXAGGERATION } from "./Globals";
import { UpdateMapScaleState, UpdateMapCompassState, UpdateMapGeolocateState } from "./Map/MapOps";
import useStore from "./store";


export interface IUserProfile
{
  active_project_id: string | null;
  map_scale: boolean;
  map_scale_units: MapScaleUnits;
  map_compass: boolean;
  map_geolocate: boolean;
  terrain_3d_exaggeration: number;
  agreed_to_terms_of_service?: boolean;

  // App-only fields (do not persist)
  isDirty: boolean;
}

//-------------------------------------------------------------------------------
// Load the user profile for the active (logged in) user.
//-------------------------------------------------------------------------------
export async function LoadUserProfile(): Promise<boolean>
{
  // Call the server to get the data

  const server = new CallServer();

  useStore.getState().store_setUserProfileIsLoading(true);  // Notify the UI that we are loading the user profile

  const result: CallServerResults = await server.Call('get', '/userprofile');

  useStore.getState().store_setUserProfileIsLoading(false);  // Notify the UI that we are no longer loading the user profile

  if(result.success)
  {
    Debug.log('UserProfile.LoadUserProfile> API server call SUCCESS');
    //Debug.log('UserProfile.LoadUserProfile> SUCCESS! data=' + JSON.stringify(result.data));

    if(!result.data)
    {
      Debug.error('UserProfile.LoadUserProfile> Received NULL user profile.');
      return false;
    }

    const newUserProfile: IUserProfile | null = result.data;
    if(!newUserProfile)
    {
      Debug.error('UserProfile.LoadUserProfile> Received NULL user profile data.');
      return false;
    }

    // If any settings are missing, set defaults for them

    if(newUserProfile.map_scale === undefined)
      newUserProfile.map_scale = true;

    if(newUserProfile.map_scale_units === undefined)
      newUserProfile.map_scale_units = 'imperial';

    if(newUserProfile.map_compass === undefined)
      newUserProfile.map_compass = true;

    if(newUserProfile.map_geolocate === undefined)
      newUserProfile.map_geolocate = true;

    if(newUserProfile.terrain_3d_exaggeration === undefined)
      newUserProfile.terrain_3d_exaggeration = DEFAULT_TERRAIN_3D_EXAGGERATION;

    // Update the state store
    useStore.getState().store_setUserProfile(newUserProfile);

    // Update the map (since it's not a React component)
    UpdateMapScaleState(newUserProfile.map_scale, newUserProfile.map_scale_units);
    UpdateMapCompassState(newUserProfile.map_compass);
    UpdateMapGeolocateState(newUserProfile.map_geolocate);

    Debug.log(`UserProfile.LoadUserProfile> User profile loaded.`);
    return true;  // success
  }
  else
  {
    // Special case - if the user doesn't have a saved user profile yet, we just use a profile with 
    // default settings and move on (treat it as success).  If the user changes any of the default 
    // settings, a save will be triggered at that point, and a new profile will get created for them.
    if(result.errorMessage === '{"detail":"user profile not found"}')
    {
      Debug.log('UserProfile.LoadUserProfile> This user has no user profile yet (defaults will be used).');

      const newUserProfile: IUserProfile = 
      {
        active_project_id: null,
        map_scale: true,
        map_scale_units: 'imperial',
        map_compass: true,
        map_geolocate: true,
        terrain_3d_exaggeration: DEFAULT_TERRAIN_3D_EXAGGERATION,

        isDirty: false,
        //join_adjacent_parcels: true,
      }
      useStore.getState().store_setUserProfile(newUserProfile);

      return true;
    }

    // Failure
    Debug.error(`UserProfile.LoadUserProfile> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Save the user profile of the active (logged in) user.
//-------------------------------------------------------------------------------
export async function SaveUserProfile(): Promise<boolean>
{
  const userProfile: IUserProfile | null = useStore.getState().store_userProfile;
  if(!userProfile) return false;

  const profile_data = 
  {
    active_project_id: userProfile.active_project_id,
    map_scale: userProfile.map_scale,
    map_scale_units: userProfile.map_scale_units,
    map_compass: userProfile.map_compass,
    map_geolocate: userProfile.map_geolocate, 
    terrain_3d_exaggeration: userProfile.terrain_3d_exaggeration,
    agreed_to_terms_of_service: userProfile.agreed_to_terms_of_service,
    //join_adjacent_parcels: userProfile.join_adjacent_parcels,

    // NOTE: We skip these on purpose:
    //       - isDirty
  }

  // Call the server to save the data

  const server = new CallServer();
  server.Add('profile_data', profile_data);

  const result = await server.Call('post', '/userprofile');

  if(result.success)
  {
    Debug.log('UserProfile.SaveUserProfile> API server call SUCCESS');
    //Debug.log('UserProfile.SaveUserProfile> SUCCESS! data=' + JSON.stringify(result.data));

    // Update the state store
    useStore.getState().store_setUserProfileIsDirty(false);

    Debug.log(`UserProfile.SaveUserProfile> User profile saved.`);
    return true;  // success
  }
  else
  {
    // Failure
    Debug.error(`UserProfile.SaveUserProfile> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}
