// Organization management

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import useStore from "../../store";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_errorRed, theme_bgColorGradient2 } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface OrgManagementProps
{
  showOrgManagement: boolean;
  setShowOrgManagement: any;
}

//-------------------------------------------------------------------------------
// Organization management component
//-------------------------------------------------------------------------------
const UserManagement = (props: OrgManagementProps) => 
{
  // Get needed state data from the store
  const { store_showAdminToolsWindow,  
        } = useStore();


  //const [orgData, setOrgData] = useState<IAdminOrgData|undefined>(undefined);
  const [orgDataIsLoading, setOrgDataIsLoading] = useState<boolean>(false);







  //-------------------------------------------------------------------------------
  // Init
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Load API data every time the window is opened

    if(props.showOrgManagement === true)
    {
      // let newOrgData: IAdminOrgData | undefined = undefined;
      // async function fetchData() 
      // {
      //   newOrgData = await LoadAdminOrgData(setOrgDataIsLoading);
      //   setOrgData(newOrgData);
      // }
      //fetchData();
    }
  }, [props.showOrgManagement])

  //-------------------------------------------------------------------------------
  // Cancel without saving changes.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setShowOrgManagement(false);
    //setOrgData(undefined);
    setOrgDataIsLoading(false);
  }








        

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog open={props.showOrgManagement===true} onClose={OnClose} maxWidth='xl' disablePortal
            PaperProps={{ sx: { minWidth: '30%', width: '70%', maxWidth: '70%', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
            Organization Management
          </Typography>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>
        <Stack sx={{ mt: 2, alignItems: 'center' }}>

          <Stack>

            <Typography sx={{ fontSize: '2.0rem', textAlign: 'center', color: theme_errorRed }}>
              NOT IMPLEMENTED YET
            </Typography>

          </Stack>

        </Stack>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

          {/* CLOSE button */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Close
              </Button>
            </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default UserManagement;
