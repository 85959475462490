// User editor component

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_bgColorLight, theme_orange, theme_errorRed } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { IAdminOrg, IAdminUser, IAdminUserData } from "./UserManagementInterfaces";
import { useEffect, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CustomTextField } from "../../LayerLibrary/EditLayer/EditLayer";
import { GridView } from "@mui/icons-material";

const FIRST_NAME_MAX_LENGTH = 15;
const LAST_NAME_MAX_LENGTH = 15;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface UserEditorProps
{
  editUser: IAdminUser | undefined;
  setEditUser: any;
  userData?: IAdminUserData;
  setUserData: any;
}

//-------------------------------------------------------------------------------
// User editor component
//-------------------------------------------------------------------------------
const UserEditor = (props: UserEditorProps) => 
{


  const [localUser, setLocalUser] = useState<IAdminUser|undefined>(undefined);
  












  //-------------------------------------------------------------------------------
  // Init
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Load local data every time the window is opened
    if(props.editUser)
      setLocalUser(props.editUser);

  }, [props.editUser])
  
  //-------------------------------------------------------------------------------
  // Close/cancel window.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setEditUser(undefined);
    setLocalUser(undefined);
  }

  //-------------------------------------------------------------------------------
  // Accept/OK.
  //-------------------------------------------------------------------------------
  async function OnAccept()
  {
    if(!props.userData || !localUser) return;
/*
    // Validate the organization

    if(!localOrgID)
    {
      ToastNotification('error', 'Please select an organization');
      return;
    }

    // Validate the list of emails

    // Split the email text based on newlines (trim spaces, remove empty lines)
    const whiteListEmails: string[] = localWhitelistText.split(/\r?\n/).map(s => s.trim()).filter(line => line.length > 0);
    if(whiteListEmails.length === 0)
    {
      ToastNotification('error', 'Please specify one or more email addresses');
      return;
    }

    // Make sure none of these emails already exist in the whitelist

    for(let i=0; i < whiteListEmails.length; i++)
    {
      const lowerCaseEmail = whiteListEmails[i].toLowerCase();
      const foundWhitelistUser: IAdminWhitelistUser | undefined = props.userData.whitelist.find(w => w.email.toLowerCase() === lowerCaseEmail);
      if(foundWhitelistUser)
      {
        ToastNotification('error', `${foundWhitelistUser.email} is already whitelisted`);
        return;
      }
    }

    // Make sure none of these emails already exist in the user list

    for(let i=0; i < whiteListEmails.length; i++)
    {
      const lowerCaseEmail = whiteListEmails[i].toLowerCase();
      const foundUser: IAdminUser | undefined = props.userData.users.find(u => u.email.toLowerCase() === lowerCaseEmail);
      if(foundUser)
      {
        ToastNotification('error', `${foundUser.email} already has an account`);
        return;
      }
    }

    // Validate the expiration date

    if(localExpirationDate)
    {
      if(localExpirationDate.isValid() === false)
      {
        ToastNotification('error', 'The expiration date is not valid');
        return;
      }
      if(localExpirationDate.isBefore(dayjs()))
      {
        ToastNotification('error', 'The expiration date cannot be in the past');
        return;
      }
    }

    // Call server

    const ret: boolean = await AddToAccountWhitelist(localOrgID, whiteListEmails, localExpirationDate?.toJSON());
    if(ret === false)
      return;

    // Notify caller
    props.OnNotifyAddToWhitelist();
*/
    // Close this window
    OnClose();
  }

  //-------------------------------------------------------------------------------
  // Get admin tool org.
  //-------------------------------------------------------------------------------
  function GetAdminToolOrg(org_id: number): IAdminOrg | undefined
  {
    if(org_id === undefined) return undefined;
    return props.userData?.orgs.find(o => o.id === org_id);
  }










  if(!props.userData || !props.editUser || !localUser) return null;

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog disablePortal 
            open={props.editUser !== undefined}
            onClose={OnClose} maxWidth='md'
            PaperProps={{ sx: { minHeight: '30vh', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1, py: 0.5 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack>
            <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain, opacity: 0.8 }}>
              {props.editUser.first_name + ' ' + props.editUser.last_name}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
              {props.editUser.email}
            </Typography>
          </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>
        <Stack sx={{ mt: 2, alignItems: 'left' }}>

          <Typography sx={{ m: 3, fontSize: '1.2rem', color: theme_errorRed }}>
            This UI is incomplete and still under development
          </Typography>

        {/* Name */}

          <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>

            {/* First Name */}

            <CustomTextField variant='filled' size='small' autoComplete='off'
                            //value={localWhitelistText}
                            //onChange={OnWhitelistTextFieldChanged}
                            inputProps={{ maxLength: FIRST_NAME_MAX_LENGTH }}
                            label=
                              {
                                <Typography sx={{fontSize:'0.8rem',color: theme_textColorBlended+'B0'}}>
                                  First Name
                                </Typography>
                              } 
                            sx={{ mt: 0, p: 0, width: '100%' }}/>

            <CustomTextField variant='filled' size='small' autoComplete='off'
                            //value={localWhitelistText}
                            //onChange={OnWhitelistTextFieldChanged}
                            inputProps={{ maxLength: LAST_NAME_MAX_LENGTH }}
                            label=
                              {
                                <Typography sx={{fontSize:'0.8rem',color: theme_textColorBlended+'B0'}}>
                                  Last Name
                                </Typography>
                              } 
                            sx={{ ml: 3, p: 0, width: '100%' }}/>
          </Stack>

          {/* Expiration date picker */}

          <Typography sx={{ mt: 3, fontSize: '0.8rem', color: theme_textColorBlended }}>
            Account Expiration Date (optional)
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                        //value={localExpirationDate}
                        //onChange={(newValue) => setLocalExpirationDate(newValue)}

                        sx={{ mt: 0.5, 
                              input: { backgroundColor: theme_bgColorLight1+'70', color: theme_textColorMain+'A0', fontSize: '1.0rem' } 
                           }}

                           // Modify the calendar popup bg color
                           slotProps={{
                            layout: {
                              sx: {
                                borderRadius: '2px',
                                backgroundColor: theme_bgColorLight+'C0',
                              }
                            }
                          }}
            />
          </LocalizationProvider>

          {/* Organizations */}

          <Typography sx={{ mt: 3, fontSize: '1rem', color: theme_orange, opacity: 0.8 }}>
            Organizations
          </Typography>

          <Grid container spacing={0.2} sx={{ mt: 1, width: '100%', maxWidth: '500px' }}>

            {props.userData.orgs.map(function(org)
              {
                // const org: IAdminOrg | undefined = GetAdminToolOrg(org_id);
                // if(!org) return null;
                return (
                  <Grid item xs={6} sx={{  }}>
                    <Typography sx={{ fontSize: '0.7rem', color: theme_textColorMain+'70' }}>
                      {org.name}
                    </Typography>
                  </Grid>
                )
              }
            )}



          </Grid>








        </Stack>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

      <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold', textTransform: 'none' }}
                      onClick={_=>OnAccept()}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default UserEditor;
