// Edit AOI group properties

import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, SelectChangeEvent, Stack, styled, Tooltip, Typography } from "@mui/material";
import useStore from "../../store";
import CloseIcon from '@mui/icons-material/Close';
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_orange, DEFAULT_AOI_COLOR, theme_limeGreen, theme_errorRed, theme_bgColorMain } from "../../Theme";
import { AOI_PORTFOLIO_MAP_AOI_ID } from "../Aois";
import { MuiColorInputStyled } from "../../LayerLibrary/EditLayer/EditLayerStyle";
import { MuiColorInputColors } from "mui-color-input";
import { CustomTextField } from "../../LayerLibrary/EditLayer/EditLayer";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { ToastNotification } from "../../ToastNotifications";
import { EditAoiAttributeList } from "./EditAoiAttributeList";
import { PortfolioMapColorSchemeEditor, PORTFOLIO_MAP_DEFAULT_COLOR_SCHEME_ID } from "./ColorSchemeEditor/ColorSchemeEditor";
import YesNoDialog from "../../Components/YesNoDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { IPortfolioMapProperties, IPortfolioMapColorScheme, PORTFOLIO_MAP_MIN_BORDER_THICKNESS, PORTFOLIO_MAP_MAX_BORDER_THICKNESS } from "./PortfolioMapInterfaces";
import { IAoiAttribute, IAoiAttributeValue, IAoiGroupProperties } from "./AoiGroupInterfaces";
import { GetNonAdminAoiAttribCount, SaveAoiGroupProperties } from "./AoiGroupOps";
import { UpdateAoiPortfolioMap } from "./PortfolioMapOps";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IAoi } from "../AoiInterfaces";
import { ZoomMapToGeojsonExtent } from "../../Map/MapOps";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


export const AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP: number = 1;
export const AOI_ADMIN_ATTRIBUTE_ID_COLOR: number = 2;

export const AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP: boolean = true;

export const PORTFOLIO_MAP_DEFAULT_BORDER_THICKNESS: number = 3;
export const PORTFOLIO_MAP_DEFAULT_BORDER_HALO: boolean = true;
export const PORTFOLIO_MAP_DEFAULT_BORDER_HALO_COLOR: string = '#000000';
export const PORTFOLIO_MAP_DEFAULT_FILL_OPACITY: number = 0.10;

const AOI_ATTRIBUTE_VALUE_TEXT_MAX_LENGTH: number = 100;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditAoiGroupPropertiesProps
{
}

//-------------------------------------------------------------------------------
// Edit AOI group properties
//-------------------------------------------------------------------------------
export function EditAoiGroupProperties(props: EditAoiGroupPropertiesProps) 
{
  // Get needed state data from the store
  const { store_editAoiGroupProperties, store_setEditAoiGroupProperties, store_aoi, 
          store_project, store_aoiGroupProperties, store_setEditAoiAttributes,
          store_aoiGroupPropertiesIsSaving, store_portfolioMapAois, 
        } = useStore();


  const [localAoiGroupProps, setLocalAoiGroupProps] = useState<IAoiGroupProperties|undefined>(undefined);
  const [changesWereMade, setChangesWereMade] = useState<boolean>(false);

  const [editColorSchemeID, setEditColorSchemeID] = useState<number|undefined>(undefined);

  const [deleteColorSchemeConfirmation, setDeleteColorSchemeConfirmation] = useState<boolean>(false);
  
  // Controls which AOI is current in EDIT mode.  We can't have everything in edit mode at 
  // once due to performance issues.
  const [aoiIdBeingEdited, setAoiIdBeingEdited] = useState<number|undefined>(undefined);







  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Initializes the local UI with values from the state store
    if(store_editAoiGroupProperties === true)
    {
      setLocalAoiGroupProps(store_aoiGroupProperties);
      setChangesWereMade(false);
    }

  }, [store_editAoiGroupProperties]);

  //-------------------------------------------------------------------------------
  // Cancel without saving changes.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    store_setEditAoiGroupProperties(false);
    setLocalAoiGroupProps(undefined);
    setChangesWereMade(false);
    setDeleteColorSchemeConfirmation(false);
    setAoiIdBeingEdited(undefined);
  }

  //-------------------------------------------------------------------------------
  // Save changes and close.
  //-------------------------------------------------------------------------------
  const OnAcceptChanges = async () => 
  {
    // Validation

    if(!localAoiGroupProps) 
      return;

    if(localAoiGroupProps.portfolio_map.border_thickness < PORTFOLIO_MAP_MIN_BORDER_THICKNESS || localAoiGroupProps.portfolio_map.border_thickness > PORTFOLIO_MAP_MAX_BORDER_THICKNESS)
    {
      ToastNotification('error', `Invalid border thickness (valid range is ${PORTFOLIO_MAP_MIN_BORDER_THICKNESS} - ${PORTFOLIO_MAP_MAX_BORDER_THICKNESS})`);
      return;
    }

    if(localAoiGroupProps.portfolio_map.fill_opacity < 0 || localAoiGroupProps.portfolio_map.fill_opacity > 1)
    {
      ToastNotification('error', `Invalid fill opacity (valid range is 0 - 1)`);
      return;
    }
  
    for(let i=0; i < localAoiGroupProps.attributes.length; i++)
    {
      // Nothing within each AOI needs validation currently...
    }

    // If changes were made, save the changes to the database

    if(changesWereMade === true)
    {
      // First update the state store and the map
      useStore.getState().store_setAoiGroupProperties(localAoiGroupProps);

      // If we are in "Aoi Portfolio Map" mode, need to visually refresh the map
      if(useStore.getState().store_aoi?.aoi_id === AOI_PORTFOLIO_MAP_AOI_ID)
        UpdateAoiPortfolioMap();
    
      // Save changes to the database
      const ret: boolean = await SaveAoiGroupProperties(localAoiGroupProps);

      if(ret === true)  // If the save worked, close the window
        OnClose();
    }
    else // No changes were made - close the window
      OnClose();
  }

  //-------------------------------------------------------------------------------
  // The "Include in portfolio map" was toggled for an AOI.
  //-------------------------------------------------------------------------------
  function OnIncludeInPortfolioMapToggle(aoi_id: number, newValue: boolean)
  {
    if(!localAoiGroupProps) return;

    const attrib: IAoiAttribute | undefined = localAoiGroupProps.attributes.find(a => a.id === AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP);
    if(!attrib) return;

    let newAoiAttribute: IAoiAttribute;

    let attribValueForAoi: IAoiAttributeValue | undefined = attrib.values.find(v => v.aoi_id === aoi_id);
    if(attribValueForAoi)
    {
      // An AOI value item already exists - we update it

      const newAoiAttribValue: IAoiAttributeValue =
      {
        ...attribValueForAoi,
        value: newValue ? 'true' : 'false'
      }
  
      newAoiAttribute =
      {
        ...attrib,
        values: attrib.values.map(oldValue => oldValue.aoi_id === aoi_id ? newAoiAttribValue : oldValue)
      }
    }
    else
    {
      // Create a new AOI value item and add it to the list

      const newAoiAttribValue: IAoiAttributeValue =
      {
        aoi_id: aoi_id,
        value: newValue ? 'true' : 'false'
      }

      newAoiAttribute =
      {
        ...attrib,
        values: [...attrib.values,newAoiAttribValue]
      }
    }

    // Replace the old attrib with the updated version

    const newLocalAoiGroupProps: IAoiGroupProperties = 
    {
      ...localAoiGroupProps,
      attributes: localAoiGroupProps.attributes.map(oldAttrib => oldAttrib.id === AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP ? newAoiAttribute : oldAttrib)
    }

    // Set the new value
    setLocalAoiGroupProps(newLocalAoiGroupProps);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // An AOI checkbox was changed.
  //-------------------------------------------------------------------------------
/*
  const OnAoiCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, aoi_id: number) => 
  {
    if(!localAoiGroupProps) return;

    const attrib: IAoiAttribute | undefined = localAoiGroupProps.attributes.find(a => a.id === AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP);
    if(!attrib) return;

    let newAoiAttribute: IAoiAttribute;

    let attribValueForAoi: IAoiAttributeValue | undefined = attrib.values.find(v => v.aoi_id === aoi_id);
    if(attribValueForAoi)
    {
      // An AOI value item already exists - we update it

      const newAoiAttribValue: IAoiAttributeValue =
      {
        ...attribValueForAoi,
        value: checked ? "true" : "false"
      }
  
      newAoiAttribute =
      {
        ...attrib,
        values: attrib.values.map(oldValue => oldValue.aoi_id === aoi_id ? newAoiAttribValue : oldValue)
      }
    }
    else
    {
      // Create a new AOI value item and add it to the list

      const newAoiAttribValue: IAoiAttributeValue =
      {
        aoi_id: aoi_id,
        value: checked ? "true" : "false"
      }

      newAoiAttribute =
      {
        ...attrib,
        values: [...attrib.values,newAoiAttribValue]
      }
    }

    // Replace the old attrib with the updated version

    const newLocalAoiGroupProps: IAoiGroupProperties = 
    {
      ...localAoiGroupProps,
      attributes: localAoiGroupProps.attributes.map(oldAttrib => oldAttrib.id === AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP ? newAoiAttribute : oldAttrib)
    }

    // Set the new value
    setLocalAoiGroupProps(newLocalAoiGroupProps);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }
*/
  //-------------------------------------------------------------------------------
  // An AOI color has changed.
  //-------------------------------------------------------------------------------
  function OnAoiColorChanged(value: string, colors: MuiColorInputColors, aoi_id: number)
  {
    if(!localAoiGroupProps) return;

    const attrib: IAoiAttribute | undefined = localAoiGroupProps.attributes.find(a => a.id === AOI_ADMIN_ATTRIBUTE_ID_COLOR);
    if(!attrib) return;

    let newAoiAttribute: IAoiAttribute;

    let attribValueForAoi: IAoiAttributeValue | undefined = attrib.values.find(v => v.aoi_id === aoi_id);
    if(attribValueForAoi)
    {
      // An AOI value item already exists - we update it

      const newAoiAttribValue: IAoiAttributeValue =
      {
        ...attribValueForAoi,
        value: value
      }
  
      newAoiAttribute =
      {
        ...attrib,
        values: attrib.values.map(oldValue => oldValue.aoi_id === aoi_id ? newAoiAttribValue : oldValue)
      }
      }
    else
    {
      // Create a new AOI value item and add it to the list


      const newAoiAttribValue: IAoiAttributeValue =
      {
        aoi_id: aoi_id,
        value: value
      }

      newAoiAttribute =
      {
        ...attrib,
        values: [...attrib.values,newAoiAttribValue]
      }
    }

    // Replace the old attrib with the updated version

    const newLocalAoiGroupProps: IAoiGroupProperties = 
    {
      ...localAoiGroupProps,
      attributes: localAoiGroupProps.attributes.map(oldAttrib => oldAttrib.id === AOI_ADMIN_ATTRIBUTE_ID_COLOR ? newAoiAttribute : oldAttrib)
    }

    // Set the new value
    setLocalAoiGroupProps(newLocalAoiGroupProps);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Return the local AOI attribute value for a specified AOI.
  //-------------------------------------------------------------------------------
  function GetLocalAoiAttributeValueForAnAoi(aoi_attribute_id: number, aoi_id: number): IAoiAttributeValue | undefined
  {
    if(!localAoiGroupProps) return undefined;

    const aoi_attributes: IAoiAttribute[] = localAoiGroupProps.attributes;
    if(!aoi_attributes) return undefined;

    for(let i=0; i < aoi_attributes.length; i++)
      if(aoi_attributes[i].id === aoi_attribute_id)
      {
        // Found the AOI attribute
        const value: IAoiAttributeValue | undefined = aoi_attributes[i].values.find(v => v.aoi_id === aoi_id);
        return value;
      }

    return undefined; // not found
  }

  //-------------------------------------------------------------------------------
  // Helper function (just to keep the main code cleaner).
  //-------------------------------------------------------------------------------
  function GetLocalAoiAttributeValue_IncludeInPortfolioMap(aoi_id: number): boolean
  {
    const valueStr: string | undefined = GetLocalAoiAttributeValueForAnAoi(AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP, aoi_id)?.value;
    if(valueStr === undefined) // Undefined - return default
      return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP;
  
    if(valueStr.toUpperCase() === 'TRUE')
      return true;
    else if(valueStr.toUpperCase() === 'FALSE')
      return false;

    // Invalid boolean value - return default
    return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP;
  }

  //-------------------------------------------------------------------------------
  // Helper function (just to keep the main code cleaner).
  //-------------------------------------------------------------------------------
  function GetLocalAoiAttributeValue_Color(aoi_id: number): string
  {
    const valueStr: string | undefined = GetLocalAoiAttributeValueForAnAoi(AOI_ADMIN_ATTRIBUTE_ID_COLOR, aoi_id)?.value;
    if(!valueStr) // Not found - return default
      return DEFAULT_AOI_COLOR;

    return valueStr;
  }

  //-------------------------------------------------------------------------------
  // The portfolio map border thickness text field has changed.
  //-------------------------------------------------------------------------------
  function OnPortfolioMapBorderThicknessChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!localAoiGroupProps) return;

    const newValueStr: string = event.target.value;
    const newValueNum: number = parseFloat(newValueStr);

    const portfolio_map: IPortfolioMapProperties = 
    {
      ...localAoiGroupProps.portfolio_map,
      border_thickness: newValueNum
    }

    // Set the new value
    setLocalAoiGroupProps({...localAoiGroupProps, portfolio_map });

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The portfolio map fill opacity text field has changed.
  //-------------------------------------------------------------------------------
  function OnPortfolioMapFillOpacityChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!localAoiGroupProps) return;

    const newValueStr: string = event.target.value;
    const newValueNum: number = parseFloat(newValueStr);

    const portfolio_map: IPortfolioMapProperties = 
    {
      ...localAoiGroupProps.portfolio_map,
      fill_opacity: newValueNum
    }

    // Set the new value
    setLocalAoiGroupProps({...localAoiGroupProps, portfolio_map });

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // An AOI checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnPortfolioMapHaloCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    if(!localAoiGroupProps) return;

    const portfolio_map: IPortfolioMapProperties = 
    {
      ...localAoiGroupProps.portfolio_map,
      border_halo: checked
    }

    // Set the new value
    setLocalAoiGroupProps({...localAoiGroupProps, portfolio_map });

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Edit the attribute list.
  //-------------------------------------------------------------------------------
  function OnEditAttributeList()
  {
    store_setEditAoiAttributes(true);
  }

  //-------------------------------------------------------------------------------
  // The attribute value has changed for one of the AOIs.
  //-------------------------------------------------------------------------------
  function OnAttributeValueChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
                                   aoiAttribute: IAoiAttribute, aoi_id: number): void 
  {
    if(!localAoiGroupProps) return;

    const newValue: string = event.target.value;

    // Prep an updated attribute value

    let oldAttribValue: IAoiAttributeValue | undefined = GetLocalAoiAttributeValueForAnAoi(aoiAttribute.id,aoi_id);

    let newAttrib: IAoiAttribute;

    if(!oldAttribValue)
    {
      // No old value: create a new one

      const newAttribValue: IAoiAttributeValue = 
      {
        aoi_id: aoi_id,
        value: newValue
      }

      // Prep a new updated attribute
      newAttrib = 
      {
        ...aoiAttribute,
        values: [...aoiAttribute.values, newAttribValue]
      }
    }
    else
    {
      // Update the existing value

      const newAttribValue: IAoiAttributeValue = 
      {
        ...oldAttribValue,
        value: newValue
      }

      // Prep a new updated attribute
      newAttrib = 
      {
        ...aoiAttribute,
        values: aoiAttribute.values.map(oldAttribValue => oldAttribValue.aoi_id === aoi_id ? newAttribValue : oldAttribValue),
      }
    }

    // Prep a new updated AOI group properties

    const newLocalAoiGroupProps: IAoiGroupProperties = 
    {
      ...localAoiGroupProps,
      attributes: localAoiGroupProps.attributes.map(oldAttrib => oldAttrib.id === aoiAttribute.id ? newAttrib : oldAttrib)
    }

    // Update the local array
    setLocalAoiGroupProps(newLocalAoiGroupProps);

    // Keep track of when changes are made (so we know to save it)
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Edit the currently-selected color scheme.
  //-------------------------------------------------------------------------------
  function OnEditColorScheme(colorSchemeID: number)
  {
    setEditColorSchemeID(colorSchemeID);
  }

  //-------------------------------------------------------------------------------
  // The color scheme combo box was changed.
  //-------------------------------------------------------------------------------
  const OnColorSchemeChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    const newColorSchemeID: number = event.target.value as number;

    if(!localAoiGroupProps) return;

    const newPortfolioMapProps: IPortfolioMapProperties = 
    {
      ...localAoiGroupProps.portfolio_map,
      active_color_scheme_id: newColorSchemeID
    }

    // Prep a new updated AOI group properties

    const newLocalAoiGroupProps: IAoiGroupProperties = 
    {
      ...localAoiGroupProps,
      portfolio_map: newPortfolioMapProps
    }

    // Update the local array
    setLocalAoiGroupProps(newLocalAoiGroupProps);

    // Keep track of when changes are made (so we know to save it)
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Create a new color scheme.
  //-------------------------------------------------------------------------------
  function OnCreateNewColorScheme()
  {
    setEditColorSchemeID(-1);  // -1 is a special ID which means "create a new color scheme"
  }

  //-------------------------------------------------------------------------------
  // Delete the currently-selected color scheme.
  //-------------------------------------------------------------------------------
  function OnDeleteActiveColorScheme()
  {
    // Close the Yes/No confirmation popup
    setDeleteColorSchemeConfirmation(false);

    if(localAoiGroupProps === undefined) return;

    const active_scheme_id = localAoiGroupProps.portfolio_map.active_color_scheme_id;

    // Update the portfolio map props to remove this color scheme

    const newPortfolioMapProps: IPortfolioMapProperties = 
    {
      ...localAoiGroupProps.portfolio_map,
      color_schemes: localAoiGroupProps.portfolio_map.color_schemes.filter(cs => cs.id !== active_scheme_id),
      active_color_scheme_id: 0  // This make the default entry the active selection (ie: no color scheme, manual colors for each AOI)
    }

    // Prep an updated version of the AOI group properties

    const newLocalAoiGroupProps: IAoiGroupProperties = 
    {
      ...localAoiGroupProps,
      portfolio_map: newPortfolioMapProps
    }

    // Update the local array
    setLocalAoiGroupProps(newLocalAoiGroupProps);

    // Keep track of when changes are made (so we know to save it)
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Confirm deletion of the active color scheme.
  //-------------------------------------------------------------------------------
  const OnConfirmDeleteColorScheme = async () => 
  {
    setDeleteColorSchemeConfirmation(true);
  }
    
  //-------------------------------------------------------------------------------
  // Returns the active portfolio map color scheme.
  //-------------------------------------------------------------------------------
  function GetActiveColorScheme(): IPortfolioMapColorScheme | undefined
  {
    if(!localAoiGroupProps) return undefined;

    for(let i=0; i < localAoiGroupProps.portfolio_map.color_schemes.length; i++)
      if(localAoiGroupProps.portfolio_map.color_schemes[i].id === localAoiGroupProps.portfolio_map.active_color_scheme_id)
        return localAoiGroupProps.portfolio_map.color_schemes[i];

    return undefined;
  }

  //-------------------------------------------------------------------------------
  // Toggle the EDIT state of the clicked AOI.
  //-------------------------------------------------------------------------------
  function OnEditAOI(aoi_id: number)
  {
    setAoiIdBeingEdited(aoi_id === aoiIdBeingEdited ? undefined : aoi_id);
  }

  //-------------------------------------------------------------------------------
  // The border halo color has changed.
  //-------------------------------------------------------------------------------
  function OnBorderHaloColorChanged(value: string, colors: MuiColorInputColors)
  {
    if(!localAoiGroupProps) return;

    const portfolio_map: IPortfolioMapProperties = 
    {
      ...localAoiGroupProps.portfolio_map,
      border_halo_color: value
    }

    // Set the new value
    setLocalAoiGroupProps({...localAoiGroupProps, portfolio_map });

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Zoom the map to this AOI.
  //-------------------------------------------------------------------------------  
  function OnZoomToAOI(aoi_id: number)
  {
    // Find the AOI (need the geometry)

    let aoi: IAoi | undefined = undefined;

    if(store_aoi?.aoi_id === AOI_PORTFOLIO_MAP_AOI_ID)
      aoi = store_portfolioMapAois.find(a => a.aoi_id === aoi_id);
    else if (store_aoi?.aoi_id === aoi_id)
      aoi = store_aoi;
    else  // can't find AOI - this should never happen
      return;

    if(!aoi || !aoi.geom)
      return;

    // Zoom the map to the AOI's geometry
    ZoomMapToGeojsonExtent(aoi.geom);

    // Close the window
    OnClose();
  }




















  if(!store_project || !store_aoi ||!localAoiGroupProps) return null;

  return (

    <Dialog disablePortal open={store_editAoiGroupProperties===true} onClose={OnClose} maxWidth='xl' 
            PaperProps={{ sx: { minWidth: '40%', width: '55%', maxWidth: '55%', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack>
            <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
              AOI Group Properties and Attributes
            </Typography>
            <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended }}>
              These values are shared among all users of this project.
            </Typography>

            {changesWereMade === true
              ?
                <Typography sx={{ mt: '5px', width:'90px', textAlign: 'center', fontSize: '0.6rem', color: theme_errorRed, fontWeight: 'bold', bgcolor: theme_bgColorMain, px: 0.4, borderRadius: 1, textTransform: 'none', boxShadow: 1 }}>
                  unsaved changes
                </Typography>
              :
                <Typography sx={{ mt: '5px', width:'60px', textAlign: 'center', fontSize: '0.6rem', color: theme_limeGreen, bgcolor: theme_bgColorMain, opacity: 0.7, px: 0.4, borderRadius: 1, textTransform: 'none', boxShadow: 1 }}>
                  no changes
                </Typography>
            }

          </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>

        <Stack sx={{ mt: 2 }}>

          {/* AOI Portfolio map properties (these apply to all AOIs in the AOI group).
              Only visible when the special "AOI Portfolio Map" is selected. */}

          {store_aoi.aoi_id === AOI_PORTFOLIO_MAP_AOI_ID
            ?
              <Stack sx={{ mb: 4 }}>

                <Typography sx={{ mb: 2, color: theme_textColorBlended, opacity: 1, fontSize: '1.2rem', fontWeight: 'bold' }}>
                  Portfolio Map Settings
                </Typography>

                <Stack>

                  {/* Color scheme controls */}

                  <Stack direction='row' sx={{ mt: 1, alignItems: 'center', justifyContent: 'start' }}>

                    <Stack direction='column' sx={{ mr: 1.5 }}>

                      <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                        Color Scheme
                      </Typography>

                      <CustomSelect variant='standard' size='small'
                                    value={localAoiGroupProps.portfolio_map.active_color_scheme_id} 
                                    onChange={OnColorSchemeChanged}
                                    sx={{ width: '100%', p: 0.5 }}>

                        {/* Default menu item */}
                        <MenuItem key={PORTFOLIO_MAP_DEFAULT_COLOR_SCHEME_ID} value={PORTFOLIO_MAP_DEFAULT_COLOR_SCHEME_ID}>
                          <Typography sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
                            [ Colors set manually for each AOI ]
                          </Typography>
                        </MenuItem>

                        {/* Saved color schemes */}

                        {localAoiGroupProps.portfolio_map.color_schemes.map(colorScheme =>

                          <MenuItem key={colorScheme.id} value={colorScheme.id}>
                            <Typography sx={{ color: theme_textColorMain+'C0', fontSize: '1.0rem' }}>
                              {colorScheme.name}
                            </Typography>
                          </MenuItem>
                        )}

                      </CustomSelect>
                    </Stack>

                    {/* The EDIT/DELETE buttons are not visible for the default color scheme menu item */}

                    {localAoiGroupProps.portfolio_map.active_color_scheme_id !== PORTFOLIO_MAP_DEFAULT_COLOR_SCHEME_ID
                      ?
                        <Stack direction='row'>

                          <Stack sx={{ ml: 0.5, alignItems: 'center' }}>
                            <IconButton onClick={(_)=>OnEditColorScheme(localAoiGroupProps.portfolio_map.active_color_scheme_id)}>
                              <EditIcon sx={{ color: theme_textColorBlended }}/>
                            </IconButton>
                            <Typography sx={{ fontSize: '0.5rem', color: theme_orange, opacity: 0.7 }}>
                              Edit
                            </Typography>
                          </Stack>

                          <Stack sx={{ ml: 0.5, alignItems: 'center' }}>
                            <IconButton onClick={(_)=>OnConfirmDeleteColorScheme()}>
                              <DeleteIcon sx={{ color: theme_textColorBlended }}/>
                            </IconButton>
                            <Typography sx={{ fontSize: '0.5rem', color: theme_orange, opacity: 0.7 }}>
                              Delete
                            </Typography>
                          </Stack>

                        </Stack>
                      :null
                    }

                    <Stack sx={{ ml: 0.5, alignItems: 'center' }}>
                      <IconButton onClick={(_)=>OnCreateNewColorScheme()}>
                        <AddCircleIcon sx={{ color: theme_textColorBlended }}/>
                      </IconButton>
                      <Typography sx={{ fontSize: '0.5rem', color: theme_orange, opacity: 0.7 }}>
                        New
                      </Typography>
                    </Stack>

                  </Stack>

                  <Stack direction='row' sx={{ mt: 3, justifyContent: 'left', alignItems: 'center' }}>

                    {/* Border thickness */}

                    <CustomTextField name='portfolioMapBorderThickness' variant='standard' size='small' autoComplete='off'
                                      disabled={store_aoiGroupPropertiesIsSaving}
                                      inputProps={{ type: 'number' }} 
                                      value={localAoiGroupProps.portfolio_map.border_thickness} 
                                      onChange={OnPortfolioMapBorderThicknessChanged}
                                      label={<Typography sx={{fontSize:'0.7rem',color: theme_textColorBlended+'B0'}}>Border Thickness ({PORTFOLIO_MAP_MIN_BORDER_THICKNESS}-{PORTFOLIO_MAP_MAX_BORDER_THICKNESS})</Typography>} 
                                      sx={{ p: 0, width: '100px' }}/>

                    {/* Border halo ON/OFF */}

                    <Stack direction='row' sx={{ ml: 4, my: 0.5, alignItems: 'center' }}>
                      <Checkbox size='small' checked={localAoiGroupProps.portfolio_map.border_halo===true} 
                          onChange={(e,c)=>OnPortfolioMapHaloCheckBoxChange(e,c)}
                          sx=
                          {{ 
                            mr: 0.5, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                            '&.Mui-checked': { color: theme_textColorBlended } 
                          }} />
                      <Typography sx={{ color: theme_textColorMain, opacity: 0.7, fontSize: '0.9rem' }}>
                        Border Halo
                      </Typography>
                    </Stack>

                    {/* Border halo color */}

                    <Stack direction='column' sx={{ ml: 5, my: 0.5 }}>
                      <Typography sx={{ color: theme_textColorBlended, fontSize: '0.7rem' }}>
                        Border Halo Color
                      </Typography>

                      <MuiColorInputStyled variant='standard' size='small' format='hex' isAlphaHidden={true}
                                           value={localAoiGroupProps.portfolio_map.border_halo_color}
                                           onChange={(v,c)=>OnBorderHaloColorChanged(v,c)}
                                           disabled={localAoiGroupProps.portfolio_map.border_halo !== true}
                                           sx={{ width: '120px' }}/>
                    </Stack>

                    {/* Fill opacity */}

                    <CustomTextField name='portfolioMapFillOpacity' variant='standard' size='small' autoComplete='off'
                                     inputProps={{ type: 'number' }} 
                                     value={localAoiGroupProps.portfolio_map.fill_opacity}
                                     onChange={OnPortfolioMapFillOpacityChanged}
                                     label={<Typography sx={{fontSize:'0.7rem',color: theme_textColorBlended+'B0'}}>Fill Opacity (0-1)</Typography>} 
                                     sx={{ ml: 7, p: 0, width: '70px' }}/>

                  </Stack>
                </Stack>

              </Stack>
            :null
          }

          {/* AOI attributes value editor */}

          <Stack direction='column' sx={{ mt: 1, mb: 1 }}>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <Typography sx={{ color: theme_textColorBlended, fontSize: '1.2rem', fontWeight: 'bold' }}>
                AOI Attribute Values Editor
              </Typography>
              <Button variant='outlined' sx={{ ml: 3, py: 0.2, px: 0.7, fontSize: '0.7rem', textTransform: 'none' }} onClick={(_)=>OnEditAttributeList()}>
                Edit Attribute List...
              </Button>
            </Stack>

            {/* Show counts (AOIs and attributes) if we're in Portfolio Map mode */}

            {store_aoi.aoi_id === AOI_PORTFOLIO_MAP_AOI_ID
              ?
                <Stack direction='row'>
                  <Typography sx={{ color: theme_textColorMain, opacity: 0.45, fontSize: '0.6rem' }}>
                    {`${store_project.aois.length} AOIs`}
                  </Typography>
                  <Typography sx={{ ml: 2, color: theme_textColorMain, opacity: 0.45, fontSize: '0.6rem' }}>
                    {`${GetNonAdminAoiAttribCount(localAoiGroupProps)} attributes`}
                  </Typography>
                </Stack>
              :null
            }
          </Stack>

          {store_project.aois.map(function(projectAoi)
          {
            // If in portfolio map mode, show all AOIs (otherwise show only the selected AOI)
            if(store_aoi.aoi_id !== AOI_PORTFOLIO_MAP_AOI_ID && store_aoi.aoi_id !== projectAoi.aoi_id) 
              return null;

            const includeInPortfolioMap: boolean = GetLocalAoiAttributeValue_IncludeInPortfolioMap(projectAoi.aoi_id);

            return (

              <Stack key={projectAoi.aoi_id} sx={{ my: 1, borderRadius: 2, 
                     bgcolor: aoiIdBeingEdited === projectAoi.aoi_id ? theme_textColorBlended+'40' : theme_textColorBlended+'15' }}>
                
                <Stack direction='row' sx={{ alignItems: 'top', justifyContent: 'space-between'}}>

                  <Stack direction='row' sx={{ alignItems: 'center' }}>

                    <Tooltip title='Include this AOI in the Portfolio Map' arrow placement='left'>
                      <IconButton sx={{ m: 0.2, p: 0.3, opacity: includeInPortfolioMap ? 1 : 0.4 }} 
                                  onClick={(_)=>OnIncludeInPortfolioMapToggle(projectAoi.aoi_id, !includeInPortfolioMap)}>
                        {includeInPortfolioMap === true
                          ?
                            <CheckBoxIcon sx={{ color: theme_textColorBlended, width: '18px', height: '18px' }}/>
                          :
                            <CheckBoxOutlineBlankIcon sx={{ color: theme_textColorBlended, width: '18px', height: '18px' }}/>
                        }
                      </IconButton>
                    </Tooltip>

                    <Tooltip title='Zoom the map to this AOI' arrow placement='top'>
                      <IconButton sx={{ m: 0.2, p: 0.3 }} 
                                  onClick={(_)=>OnZoomToAOI(projectAoi.aoi_id)}>
                        <VisibilityIcon sx={{ color: theme_textColorBlended, width: '18px', height: '18px' }}/>
                      </IconButton>
                    </Tooltip>

                    <Typography sx={{ ml: 1, p: 0, color: theme_orange, fontSize: '1.0rem', fontWeight: 'bold' }}>
                      {projectAoi.aoi_name}
                    </Typography>

                  </Stack>

                  <Tooltip title={aoiIdBeingEdited === projectAoi.aoi_id ?  'Stop editing attribute values for this AOI' : 'Edit attribute values for this AOI'} arrow placement='right'>
                    <IconButton onClick={(_)=>OnEditAOI(projectAoi.aoi_id)}>
                      <EditIcon sx={{ color: aoiIdBeingEdited === projectAoi.aoi_id ? theme_orange : theme_textColorBlended }}/>
                    </IconButton>
                  </Tooltip>

                </Stack>

                {includeInPortfolioMap !== true
                  ?
                    <Typography sx={{ mb: 0.8, ml: 3, fontSize: '0.7rem', color: theme_textColorMain, opacity: 0.4 }}>
                      Not included in the portfolio map
                    </Typography>
                  :null
                }
                
                {/* Show the attributes in either VIEW or EDIT mode.  Only 1 AOI at a time can be in EDIT modes
                    for performance reasons. */}

                {aoiIdBeingEdited === projectAoi.aoi_id
                  ?
                    // === EDIT MODE ===

                    <Stack sx={{ mx: 3, mb: 1 }}>

                      {/* ADMIN attribute:  Color */}

                      <Stack direction='column' alignItems='start'>
                        <MuiColorInputStyled variant='standard' size='small' format='hex' isAlphaHidden={true}
                                             value={GetLocalAoiAttributeValue_Color(projectAoi.aoi_id)}
                                             onChange={(v,c)=>OnAoiColorChanged(v,c,projectAoi.aoi_id)}
                                             disabled={localAoiGroupProps.portfolio_map.active_color_scheme_id !== PORTFOLIO_MAP_DEFAULT_COLOR_SCHEME_ID}
                                             sx={{ width: '120px' }}/>

                        {localAoiGroupProps.portfolio_map.active_color_scheme_id !== PORTFOLIO_MAP_DEFAULT_COLOR_SCHEME_ID
                          ?
                            <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '0.5rem' }}>
                              The color is controlled by the color scheme
                            </Typography>
                          :null
                        }
                      </Stack>

                      {/* Attribute values for all user (non-admin) attributes */}

                      <Stack sx={{ mt: 1 }}>

                        {localAoiGroupProps.attributes.map(function(aoiAttribute)
                        {
                          // We only handle non-admin attributes here.  Admin attributes are hard-coded up above.
                          if(aoiAttribute.is_admin === true) return null;

                          // TYPE:  NUMBER

                          if(aoiAttribute.type === 'number')
                          return (
                            <CustomTextField key={aoiAttribute.id} variant='standard' size='small' autoComplete='off'
                                             value={GetLocalAoiAttributeValueForAnAoi(aoiAttribute.id,projectAoi.aoi_id)?.value}
                                             onChange={e=>OnAttributeValueChanged(e,aoiAttribute,projectAoi.aoi_id)}
                                             inputProps={{ type: 'number' }}
                                             label=
                                              {
                                                <Stack direction='row' sx={{ alignItems: 'center' }}>
                                                  <Typography sx={{ color: theme_textColorBlended }}>
                                                    {aoiAttribute.name}
                                                  </Typography>
                                                  {aoiAttribute.units && aoiAttribute.units.length > 0
                                                    ?
                                                      <Typography sx={{ color: theme_limeGreen, ml: 2, opacity: 0.7, fontSize: '0.8rem' }}>
                                                        ({aoiAttribute.units})
                                                      </Typography>
                                                    :null
                                                  }
                                                </Stack>
                                              }
                                              sx={{ p: 0, my: 0.6 }}/>
                            )

                            // TYPE:  TEXT

                            else if(aoiAttribute.type === 'text')
                            return (
                              <CustomTextField key={aoiAttribute.id} variant='standard' size='small' autoComplete='off'
                                               value={GetLocalAoiAttributeValueForAnAoi(aoiAttribute.id,projectAoi.aoi_id)?.value}
                                               onChange={e=>OnAttributeValueChanged(e,aoiAttribute,projectAoi.aoi_id)}
                                               inputProps={{ maxLength: AOI_ATTRIBUTE_VALUE_TEXT_MAX_LENGTH }}
                                               label=
                                                {
                                                  <Stack direction='row' sx={{ alignItems: 'center' }}>
                                                    <Typography sx={{ color: theme_textColorBlended }}>
                                                      {aoiAttribute.name}
                                                    </Typography>
                                                    {aoiAttribute.units && aoiAttribute.units.length > 0
                                                      ?
                                                        <Typography sx={{ color: theme_limeGreen, ml: 2, opacity: 0.7, fontSize: '0.8rem' }}>
                                                          ({aoiAttribute.units})
                                                        </Typography>
                                                      :null
                                                    }
                                                  </Stack>
                                                }
                                                sx={{ p: 0, my: 0.6 }}/>
                              )
                              else
                              return (
                                <Typography key={aoiAttribute.id} sx={{ color: theme_errorRed }}>
                                  [ Unsupported AOI attribute type ]
                                </Typography>
                              )
                        })}
                      </Stack>
                    </Stack>
                  :
                    // === VIEW MODE ===

                    <Stack sx={{ mx: 3, mb: 1 }}>

                      <Stack direction='row' sx={{ alignItems: 'center' }}>

                        {localAoiGroupProps.portfolio_map.active_color_scheme_id === PORTFOLIO_MAP_DEFAULT_COLOR_SCHEME_ID
                          ?
                            <Stack direction='row' sx={{ alignItems: 'center' }}>
                              <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended }}>
                                Color:
                              </Typography>
                              <Box sx={{ ml: 1, width: '20px', height: '16px', borderRadius: 1, 
                                         bgcolor: GetLocalAoiAttributeValue_Color(projectAoi.aoi_id) }}/>
                            </Stack>
                          :null
                        }

                      </Stack>

                      {/* Attribute values for all attributes (non-admin) */}

                      <Stack sx={{ mt: 1 }}>

                        {localAoiGroupProps.attributes.map(function(aoiAttribute)
                        {
                          // We only handle non-admin attributes here.  Admin attributes are hard-coded up above.
                          if(aoiAttribute.is_admin === true) return null;

                          return (

                            <Stack key={aoiAttribute.id} direction='row' sx={{ alignItems: 'center' }}>

                              <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                                {aoiAttribute.name}
                              </Typography>

                              <Stack direction='row' sx={{ ml: 1, alignItems: 'center' }}>

                                <Typography sx={{ color: theme_limeGreen, opacity: 0.9, fontSize: '0.8rem' }}>
                                  {GetLocalAoiAttributeValueForAnAoi(aoiAttribute.id,projectAoi.aoi_id)?.value}
                                </Typography>

                                {aoiAttribute.units && aoiAttribute.units.length > 0
                                  ?
                                    <Typography sx={{ ml: 0.6, color: theme_orange, opacity: 0.7, fontSize: '0.7rem' }}>
                                      {aoiAttribute.units}
                                    </Typography>
                                  :null
                                }
                              
                              </Stack>
                            </Stack>
                          )
                        })}
                      </Stack>

                    </Stack>
                }

              </Stack>
            )
          })}

        </Stack>

        {/* AOI Attribute list editor popup window */}

        <EditAoiAttributeList localAoiGroupProps={localAoiGroupProps} setLocalAoiGroupProps={setLocalAoiGroupProps} 
                              setChangesWereMade={setChangesWereMade}/>

        {/* Portfolio Map color scheme editor popup window */}

        <PortfolioMapColorSchemeEditor editColorSchemeID={editColorSchemeID} setEditColorSchemeID={setEditColorSchemeID}
                                     aoiGroupProps={localAoiGroupProps} setAoiGroupProps={setLocalAoiGroupProps}
                                     setChangesWereMade={setChangesWereMade} />

        {/* "Delete color scheme Yes/No confirmation dialog */}

        <YesNoDialog open={deleteColorSchemeConfirmation} 
                     setOpen={setDeleteColorSchemeConfirmation}
                     onYes={OnDeleteActiveColorScheme}>
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <DeleteIcon sx={{ mr: 2, color: theme_textColorBlended }} />
            <Box sx={{ color: theme_textColorMain }}>Delete color scheme</Box>
            <Box sx={{ ml: 1, color: theme_orange, fontWeight: 'bold' }}>{GetActiveColorScheme()?.name}</Box>
            <Box sx={{ ml: 1, color: theme_textColorMain }}>?</Box>
          </Stack>
        </YesNoDialog>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold' }}
                      onClick={OnAcceptChanges}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>
  )
}



// Customized MUI Select
export const CustomSelect = styled(Select)(() => (
{
  fontSize: '0.9rem', 
  color: theme_textColorMain, 
  opacity: 0.8,

  // These change the underline color when using the standard variant
  ':before': { borderBottomColor: theme_textColorBlended },
  ':after': { borderBottomColor: theme_textColorBlended },

  '& .MuiSvgIcon-root': { color: theme_textColorBlended }
}));
